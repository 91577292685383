import { alpha, styled } from '@mui/system'
import MuiToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

const Img = styled('img')`
	border: 1px solid ${({ theme }) => theme.palette.grey.medium};
	border-radius: 100%;
	height: 100%;
	width: 100%;
`
const shouldForwardProp = (prop) => !['hex', 'isProductImage'].includes(prop)

const Root = styled('div')`
	display: flex;
	align-items: flex-start;
`

const ToggleButtonGroupStyled = styled(ToggleButtonGroup)`
	width: 100%;
	flex-wrap: wrap;
`

const TextToggleButton = styled(MuiToggleButton, { shouldForwardProp })`
	border-radius: 0%;
	border: 1px solid transparent;
	min-height: 35px;
	min-width: 35px;
	padding: 6px;
	margin-right: 4px;
	margin-bottom: 4px;
	color: ${({ theme }) => theme.palette.primary.main};
	:hover {
		border: 1px solid ${({ theme }) => theme.palette.grey.main};
	}
	&.MuiToggleButtonGroup-grouped {
		background-color: transparent;
		:hover {
			border: 1px solid ${({ theme }) => theme.palette.grey.main};
		}
		&.Mui-selected:hover {
			background-color: transparent;
		}

		&.Mui-selected {
			border: 1px solid ${({ theme }) => theme.palette.grey.main};
			& + .MuiToggleButtonGroup-grouped.Mui-selected {
				border-left: 1px solid ${({ theme }) => theme.palette.grey.main};
			}
		}
		&:not(:first-of-type) {
			margin-left: 0;
		}
	}
	&.Mui-disabled {
		border: 1px solid transparent;
		opacity: 0.2;
	}
	&.Mui-selected:hover {
		background-color: transparent;
	}
	&.Mui-selected {
		background-color: transparent;
		border: 1px solid ${({ theme }) => theme.palette.grey.main};
	}
	&.out-of-stock {
		border: 1px solid transparent;
		opacity: 0.2;
	}
`

const ToggleButton = styled(MuiToggleButton, { shouldForwardProp })`
	border: 1px solid transparent;
	border-radius: 100%;
	height: 46px;
	padding: 2px;
	margin-right: 4px;
	margin-bottom: 4px;
	width: 46px;
	:hover {
		background-color: transparent;
		border: 1px solid ${({ theme }) => theme.palette.grey.medium};
	}
	&.MuiToggleButtonGroup-grouped {
		border: 1px solid ${({ theme }) => theme.palette.grey.light};

		&:not(:last-of-type) {
			border-radius: 100%;
			border: 1px solid ${({ theme }) => theme.palette.grey.light};
		}
		&:not(:first-of-type) {
			border-radius: 100%;
			border: 1px solid ${({ theme }) => theme.palette.grey.light};
			margin-left: 0;
		}
		&:not(.Mui-selected):hover {
			background-color: transparent;
			border: 1px solid ${({ theme }) => theme.palette.grey.medium};
		}
		&.Mui-selected {
			border: 1px solid ${({ theme }) => theme.palette.grey.main};
			& + .MuiToggleButtonGroup-grouped.Mui-selected {
				border-left: 1px solid ${({ theme }) => theme.palette.grey.main};
			}
		}
	}
	&.Mui-selected {
		border: 1px solid ${({ theme }) => theme.palette.grey.main};
	}
	&:not(.Mui-disabled) {
		&:before {
			border: 1px solid ${({ theme }) => theme.palette.grey.medium};
		}
	}
	&.Mui-disabled {
		border: 1px solid transparent;
		&:before {
			background-color: ${({ hex }) => (hex ? alpha(hex, '0.3') : 'rgba(255, 255, 255, 0.3)')};
			border-radius: 100%;
			bottom: 2px;
			content: '';
			left: 2px;
			position: absolute;
			right: 2px;
			top: 2px;
		}
		&:after {
			background-color: #fff;
			bottom: 0;
			content: '';
			left: 50%;
			position: absolute;
			top: 0;
			transform: rotate3d(0, 0, 1, 45deg) translateX(-50%);
			width: 2px;
		}
		span {
			background-color: transparent;
		}
	}
	&.Mui-selected {
		background-color: transparent;
		&:hover {
			background-color: transparent;
		}
	}
	& span {
		background-color: ${({ hex }) => hex};
		border: 1px solid ${({ theme }) => theme.palette.grey.medium};
		height: 100%;
		width: 100%;
	}
	&.out-of-stock {
		border: 1px solid transparent;
		opacity: 0.2;
	}
	img {
		border: 1px solid ${({ theme }) => theme.palette.grey.medium};
		border-radius: 100%;
		height: 100%;
		width: 100%;
	}
	${({ isProductImage }) =>
		isProductImage
			? `
			&.MuiToggleButtonGroup-grouped {
				&:not(:first-of-type),
				&:not(:last-of-type) {
					border-radius: 0;
				}
			}
			position: relative;
			width: 30px;
			height: 30px;
			border-radius: 0;
			img {
				border: 0;
				border-radius: 0;
			}
		`
			: ''}
`

export { Root, Img, ToggleButtonGroupStyled, ToggleButton, TextToggleButton }
